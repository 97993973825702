class Enhancements {

    init() {
        const colorSchemeToggle = new ColorSchemeToggle();
        colorSchemeToggle.init();
    }
}

class ColorSchemeToggle {

    constructor() {
        this.STORAGE_KEY = 'user-color-scheme';
        this.STYLE_KEY = '--color-scheme';
        this.toggleButton = document.createElement('button');
        this.statusElement = document.createElement('span');
    }

    init() {
        const footer = document.querySelector('.site-footer');
        if (!footer) {
            console.info('No footer to add color scheme toggle.');
            return;
        }

        this.statusElement.setAttribute('class', '_visually-hidden');
        this.statusElement.setAttribute('role', 'status');
        this.toggleButton.setAttribute('class', 'switch');
        this.toggleButton.setAttribute('aria-live', 'off');

        const colorSchemeToggle = document.createElement('span');
        colorSchemeToggle.setAttribute('class', 'color-scheme-toggle');
        colorSchemeToggle.append(this.statusElement);
        colorSchemeToggle.append(this.toggleButton);

        try {
            this.applyPreference();
            this.toggleButton.addEventListener('click', this.handleToggle.bind(this), true);
            footer.prepend(colorSchemeToggle);
            document.body.style.transition = 'color 0.2s ease, background 0.2s ease-in-out';
        } catch (error) {
            console.error(error.message);
        }
    }

    applyPreference() {
        const setting = localStorage.getItem(this.STORAGE_KEY);
        if (setting) {
            document.documentElement.setAttribute('data-' + this.STORAGE_KEY, setting);
        }
        this.setButtonLabelAndStatus(this.getCurrentMode());
    }

    setButtonLabelAndStatus(mode) {
        this.statusElement.textContent = `${mode} color mode enabled`;
        this.toggleButton.textContent = `Enable ${mode === 'dark' ? 'light' : 'dark'} mode.`;
    }

    getCurrentMode() {
        let currentMode = window.getComputedStyle(document.documentElement).getPropertyValue(this.STYLE_KEY);
        currentMode = currentMode.replace(/"/g, '').trim();
        if (!currentMode) {
            throw new Error('Color mode cannot be determined from styling.');
        }
        return currentMode;
    }

    handleToggle(event) {
        event.preventDefault();
        this.toggleMode();
    }

    toggleMode() {
        try {
            const mode = this.getCurrentMode();
            let setting;
            switch (mode) {
                case 'light':
                    setting = 'dark';
                    break;
                case 'dark':
                    setting = 'light';
                    break;
                default:
                    setting = 'light';
            }
            this.storeSetting(setting);
            this.applyPreference();
        } catch (error) {
            console.error(error.message);
        }
    }

    storeSetting(setting) {
        localStorage.setItem(this.STORAGE_KEY, setting);
    }
}
// TODO More graceful fallback when current color mode can't be determined (due to style not yet loaded)
window.addEventListener('DOMContentLoaded', (event) => {
    let enhancements = new Enhancements();
    enhancements.init();
});
